import { getSignatureDefault } from '_api/wx';
import store from '@/store';

const SET_EXTERBALUSERID = userId => store.commit('SET_EXTERBALUSERID', userId);

export const geWxAgentConfig = () => {
  let url = window.location.href.split('#')[0];
  return new Promise((resolve) => {
    getSignatureDefault(process.env.VUE_APP_SECRET, url).then(res => { // process.env.VUE_APP_CORPSECRET
      if (res.flag) {
        wx.agentConfig({
          corpid: process.env.VUE_APP_CORPID, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: process.env.VUE_APP_AGENTID, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['getContext', 'getCurExternalContact', 'openEnterpriseChat'], // 必填，传入需要使用的接口名称 //'sendChatMessage'
          success: () => {
            // 回调
            wx.invoke('getContext', {}, function(res) {
              if (res.err_msg === 'getContext:ok') {

              } else {
                // 错误处理
              }
            });
            wx.invoke('getCurExternalContact', {}, res => {
              if (res.err_msg === 'getCurExternalContact:ok') {
                SET_EXTERBALUSERID(res.userId) ; // 返回当前外部联系人userId
                resolve('');
              } else {
                // 错误处理
              }
            });
          },
          fail: function(res) {
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级');
            }
          }
        });
      } else {
        this.$showToast(res.errorMsg);
      }
    });
  });
};
