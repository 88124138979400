<template>
  <div class="page-out">
    <div
      class="page-out-ing"
      :class="isPcMobileClass()"
    >
      <div class="page-box">
        <div class="img-box">
          <img
            src="@/assets/img/icon_tips_tongzhi@2x.png"
            alt=""
          >
        </div>
        <div class="page-title">
          您明天有条商机待跟进，请尽快去联系
        </div>
        <div class="page-content">
          <view-match
            :list="basicList"
            :need-show-hide="true"
          />
        </div>
      </div>
      <div class="page-contactPerson">
        <div
          v-for="(item, index) of personnelList"
          :key="index"
          class="card-box"
        >
          <div class="card-cont">
            <div class="card-avatar">
              <div class="card-avatar-name">
                {{ interceptNames(item.title) }}
              </div>
            </div>
            <div class="card-content">
              <div class="card-title">
                {{ item.title }}
              </div>
              <div class="card-soure-time">
                <div class="card-time">
                  {{ `${item.differenceTime}天前 · ${item.timeText}添加` }}
                </div>
              </div>
            </div>
            <div
              class="page-jump"
              @click="jumpChat(item.wxUserid)"
            >
              <img
                src="@/assets/img/icon_wechat@2x.png"
                alt=""
              >
              <span class="page-jump-span">去联系</span>
            </div>
          </div>
          <div
            v-if="item.tab !== ''"
            class="card-status"
          >
            {{ item.tab }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays, findValueByKey, differenceHour, isPcMobile} from '@/lib/until.js';
import { interceptName } from '_lib/until';
import { noticeSellerTrackDetail } from '@/api/follow';
import { geWxAgentConfig } from '_lib/enterprise-weChat';
import { mapGetters } from 'vuex';
export default {
  components: {
    viewMatch
  },
  data() {
    return {
      basicData: {
        custCn: {
          title: '企业名称',
          value: ''
        },
        comboName: {
          title: '产品名称',
          value: ''
        },
        trackType: {
          title: '上次跟进类型',
          value: ''
        },
        trackDate: {
          title: '上次跟进日期',
          value: ''
        },
        accompanys: {
          title: '陪访人员',
          value: ''
        },
        custRequire: {
          title: '客户需求',
          value: []
        },
        trackReliable: {
          title: '进程推进',
          value: []
        },
        oppoLevel: {
          title: '商机等级',
          value: ''
        },
        oppoStatus: {
          title: '商机状态',
          value: ''
        },
        custContractName: {
          title: '联系人',
          value: ''
        },
        trackRemark: {
          title: '跟进内容',
          value: ''
        }
      },
      personnelList: []
    };
  },
  computed: {
    ...mapGetters(['dictList', 'externalUserid']),
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    }
  },
  created() {
    geWxAgentConfig();
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    isPcMobileClass() {
      return isPcMobile() === 2 ? 'screenControl' : '';
    },
    interceptNames(val) {
      return interceptName(val);
    },
    getDetail() {
      noticeSellerTrackDetail(this.$route.query.id).then(res => {
        if (res.flag) {
          let data = {...res.data.oppoInfo, ...res.data.trackInfo};
          for (let i in this.basicData) {
            if (i !== 'accompanys') {
              this.basicData[i].value = data[i];
            }
          }
          this.basicList.map((item, index) => {
            if (item.title === '陪访人员') {
              this.basicList.splice(index, 1);
            }
          });
          this.basicData.comboName.value = `${data.busTypeName} - ${data.comboName}`;
          this.basicData.oppoLevel.value = data.oppoLevel ? findValueByKey(this.dictList.oppoLevel, data.oppoLevel) + '级' : '';
          this.basicData.oppoStatus.value = findValueByKey(this.dictList.oppoStatus, data.oppoStatus);
          this.basicData.trackType.value = findValueByKey(this.dictList.trackType, data.trackType);
          this.basicData.trackReliable.value = findValueByKey(this.dictList.reliable, data.trackReliable);
          if (data.isAccompany === 1) {
            this.basicData.accompanys.value = data.accompanys !== null && data.accompanys;
          } else {
            this.basicList.map((item, index) => {
              if (item.title === '陪访人员') {
                this.basicList.splice(index, 1);
              }
            });
          }
          if (data.custContractName === null && data.custContractPosition === null) {
            this.basicList.map((item, index) => {
              if (item.title === '联系人') {
                this.basicList.splice(index, 1);
              }
            });
          } else {
            this.basicData.custContractName.value = (data.custContractName ? data.custContractName : '') + (data.custContractPosition ? '-' + data.custContractPosition : '');
          }
          res.data.wxCustInfo.forEach(item => {
            this.personnelList.push(
              {
                title: item.wxRemarkName,
                differenceTime: differenceHour(item.wxCreateTime).days,
                timeText: item.wxCreateTime,
                tab: item.wxTagName,
                wxUserid: item.wxUserid
              }
            );
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    jumpChat(wxUserid) {
      wx.openEnterpriseChat({
        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
        userIds: '', // 参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
        externalUserIds: wxUserid, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
        groupName: '', // 会话名称。单聊时该参数传入空字符串""即可。
        chatId: '', // 若要打开已有会话，需指定此参数。如果是新建会话，chatId必须为空串
        success: (res) => {
          console.log(res.chatId);
          // var chatId = res.chatId; //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
          // 回调
        },
        fail: (res) => {
          if (res.errMsg.indexOf('function not exist') > -1) {
            alert('版本过低请升级');
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.page-out{
  background-color: $color-F7;
  .page-out-ing{
    // margin: 15px;
  }
  .page-box{
    margin: 15px;
    overflow: hidden;
    background-color: $color-FFF;
    border-radius: 8px;
    .img-box{
      width: 60px;
      height: 60px;
      margin: 32px auto;
      margin-bottom: 24px;
      img {
        width: 60px;
      }
    }
    .page-title{
      margin: 0 52px;
      color: #23252E;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
    .page-content{
      margin: 32px 16px 0 16px;
    }
  }
  .page-contactPerson {
    overflow: hidden;
    background-color: $color-F7;
    .card-box {
      width: 100%;
      margin-bottom: 12px;
      overflow: hidden;
      background-color: $color-FFF;
      border-radius: 8px;
      .card-cont {
        display: flex;
        justify-content: flex-start;
        margin: 20px 16px 16px 16px;
        .card-avatar {
          margin-right: 12px;
          .card-avatar-name{
            width: 40px;
            height: 40px;
            color: #FFF;
            font-weight: 600;
            font-size: $font-12;
            line-height: 40px;
            text-align: center;
            background: linear-gradient(180deg, #B6CAD9 0%, #9BB7CC 100%);
            border-radius: 50%;
          }
        }
        .card-content {
          flex: 1;
          text-align: left;
          .card-title{
            margin-bottom: 4px;
            color: #23252E;
            font-size: $font-16;
            line-height: 20px;
          }
          .card-soure-time{
            display: flex;
            justify-content: flex-start;
            .card-time{
              color:#999BA3;
              font-size: $font-14;
              line-height: 18px;
            }
          }
        }
      }
      .page-jump{
        display: flex;
        flex-direction: column;
        align-self: center;
        // width: 24px;
        // height: 24px;
        img{
          align-self: center;
          width: 24px;
          margin-bottom: 2px;
        }
        .page-jump-span{
          color: $color-theme;
          font-size: $font-12;
          line-height: 16px;
        }
      }
      .card-status{
        padding: 7px 0 12px 16px;
        color: #5A5C66;
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        border-top:1px solid #EAEBED;
      }
    }
  }
}
</style>
