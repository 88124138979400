import { baseRequest } from './index';

/**
 * 获取wx.agentConfig配置
 * @params corpsecret string 企业微信应用id
 * @params url string 当前网页的URL，不包含#及其后面部分
 * @returns MenusInfo
*/
export const getSignatureDefault = (corpsecret, url) => baseRequest.post('/thirdparty/qywx/getSignatureDefault', {corpsecret, url});

